import axios from 'axios'

const instance = axios.create({
    baseURL: '',
    timeout: 10000,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//添加请求拦截器
instance.interceptors.request.use(
    config => {
        return config
    },
    err => {
        err.msg = '服务异常，请稍后重试！'
        return Promise.resolve(err)
    }
)

// 添加响应拦截器
instance.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        response = response.data
        if (response.errcode == 30111) {
            console.log('用户未登录，将跳转登录')
            return window.location.href = "/api/qyloginurl";
        }
        return response;
    },
    error => {
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

export default instance