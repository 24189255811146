<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin: 0 auto;
}
img {
    object-fit: cover;
}
</style>
