import request from './request'

let https = {
    get: (url, params, method = 'get') => {
        // url = url + '?dev=dev'//拉取固定用户
        return new Promise((re) => {
            let res = request({
                url,
                method,
                params
            })
            re(res)
        })
    },

    post: (url, data, method = 'post') => {
        // url = url + '?dev=dev'//拉取固定用户
        return new Promise((re) => {
            let res = request({
                url,
                method,
                data
            })
            re(res)
        })
    }
}

export default https