import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../views/Home.vue'),
        children: [
            {
                path: '/',
                name: 'Index',
                component: () => import('@/views/Index/Index.vue'),
                meta: { keepAlive: false, title: '首页' },
            },
            {
                path: '/teacherList',
                name: 'TeacherList',
                component: () => import('@/views/Index/TeacherList.vue'),
                meta: { keepAlive: false, title: '讲师库' },
            },
            {
                path: '/teacherDetail',
                name: 'TeacherDetail',
                component: () => import('@/views/Index/TeacherDetail.vue'),
                meta: { keepAlive: false, title: '讲师详情' },
            },
            {
                path: '/SearchResult',
                name: 'SearchResult',
                component: () => import('@/views/Index/SearchResult.vue'),
                meta: { keepAlive: false, title: '搜索结果' },
            },
            {
                path: '/CurriculumTypeList',
                name: 'CurriculumTypeList',
                component: () => import('@/views/Curriculum/CurriculumTypeList.vue'),
                meta: { keepAlive: false, title: '课程分类' },
            },
            {
                path: '/CurriculumDetail',
                name: 'CurriculumDetail',
                component: () => import('@/views/Curriculum/CurriculumDetail.vue'),
                meta: { keepAlive: false, title: '课程详情' },
            },
            //线下课程详情
            {
                path: 'OffLineCurriculumDetail',
                name: 'OffLineCurriculumDetail',
                component: () => import('@/views/Curriculum/OffLineCurriculumDetail.vue'),
                meta: { keepAlive: false, title: '课程详情' },
            },
            {
                path: '/Evaluation',
                name: 'Evaluation',
                component: () => import('@/views/Evaluation/Evaluation.vue'),
                meta: { keepAlive: false, title: '测评列表' },
            },
            {
                path: '/InTest',
                name: 'InTest',
                component: () => import('@/views/Evaluation/InTest.vue'),
                meta: { keepAlive: false, title: '测试中' },
            },
            {
                path: '/TestResult',
                name: 'TestResult',
                component: () => import('@/views/Evaluation/TestResult.vue'),
                meta: { keepAlive: false, title: '测试结果' },
            },
            {
                path: '/ErrAnalysis',
                name: 'ErrAnalysis',
                component: () => import('@/views/Evaluation/ErrAnalysis.vue'),
                meta: { keepAlive: false, title: '错题解析' },
            },
            {
                path: '/Questionnaire',
                name: 'Questionnaire',
                component: () => import('@/views/Evaluation/Questionnaire.vue'),
                meta: { keepAlive: false, title: '问卷调查' },
            },
            {
                path: '/MyInfo',
                component: () => import('@/views/My/MyInfo.vue'),
                meta: { keepAlive: false, title: '个人中心' },
                children: [
                    {
                        path: '/',
                        name: 'MyIndex',
                        component: () => import('@/views/My/view/MyIndex.vue'),
                        meta: { keepAlive: false, title: '个人中心' },
                    },
                    //我的积分
                    {
                        path: 'integral',
                        name: 'MyIntegral',
                        component: () => import('@/views/My/view/MyIntegral.vue'),
                        meta: { keepAlive: false, title: '我的积分' },
                    },
                    //收藏夹
                    {
                        path: 'Favorite',
                        name: 'Favorite',
                        component: () => import('@/views/My/view/Favorite.vue'),
                        meta: { keepAlive: false, title: '收藏夹' },
                    },
                    //讲师中心 
                    {
                        path: 'Lecturer',
                        name: 'Lecturer',
                        component: () => import('@/views/My/view/Lecturer.vue'),
                        meta: { keepAlive: false, title: '讲师中心' },
                    },
                    //我的证书 
                    {
                        path: 'Certificate',
                        name: 'Certificate',
                        component: () => import('@/views/My/view/Certificate.vue'),
                        meta: { keepAlive: false, title: '我的证书' },
                    },
                    //消息中心
                    {
                        path: 'News',
                        name: 'News',
                        component: () => import('@/views/My/view/News.vue'),
                        meta: { keepAlive: false, title: '消息中心' },
                    },
                    //我的成长值 
                    {
                        path: 'Growth',
                        name: 'Growth',
                        component: () => import('@/views/My/view/Growth.vue'),
                        meta: { keepAlive: false, title: '成长值' },
                    },
                    //学习地图
                    {
                        path: 'Map',
                        name: 'Map',
                        component: () => import('@/views/My/view/Map.vue'),
                        meta: { keepAlive: false, title: '学习地图' },
                    },
                    //我的师傅
                    {
                        path: 'Master',
                        name: 'Master',
                        component: () => import('@/views/My/view/Master.vue'),
                        meta: { keepAlive: false, title: '我的师傅' },
                    },
                    //师傅列表
                    {
                        path: 'LearnTeacher',
                        name: 'LearnTeacher',
                        component: () => import('@/views/My/view/LearnTeacher.vue'),
                        meta: { keepAlive: false, title: '我的师傅' },
                    },
                    //我的徒弟
                    {
                        path: 'Apprentice',
                        name: 'Apprentice',
                        component: () => import('@/views/My/view/Apprentice.vue'),
                        meta: { keepAlive: false, title: '我的徒弟' },
                    },
                    //徒弟详情
                    {
                        path: 'ApprenticeDetail',
                        name: 'ApprenticeDetail',
                        component: () => import('@/views/My/view/ApprenticeDetail.vue'),
                        meta: { keepAlive: false, title: '我的徒弟' },
                    },
                    //我的学习任务
                    {
                        path: 'Target',
                        name: 'Target',
                        component: () => import('@/views/My/view/Target.vue'),
                        meta: { keepAlive: false, title: '我的学习任务' },
                    },
                ]
            },
        ]
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import('@/404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

export default router
