import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        navActive: 0,//顶部导航栏下标
        myActive: -1,//个人中心侧栏下标
        user: {
            avatar: require('@/assets/defulatAvatar.png'),
            name: "",
            department_info: {
                working_years: "",
                department: "",
                position: "",
            },
        },//用户信息
        isLogin: false,//是否登录
        newsNum: 0,//消息数
        ossToken: {},//阿里云token
    },
    mutations: {
        //存储oss
        setOssToken(state, e) {
            state.ossToken = JSON.parse(window.localStorage.getItem('ossToken') || "{}")
        },
        //存储用户信息
        setUser(state, e) {
            state.user = JSON.parse(window.localStorage.getItem('user') || "{}")
            if (state.user && state.user.name) {
                state.isLogin = true
            }
        },
        //切换顶部栏下标
        switchNav(state, e) {
            state.navActive = e
        },
        //切换个人中心标签下标
        switchMyNav(state, e) {
            state.myActive = e
        },
        //获取未读消息
        newsNumber(state, e) {
            state.newsNum = e
        }
    },
    actions: {
    },
    modules: {
    }
})
