import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import https from './utils/https'
import VueWechatTitle from 'vue-wechat-title'
import '@/styles/main.less';

Vue.use(VueWechatTitle)
Vue.use(ElementUI);

// 注册一个全局自定义指令 `v-focus`
Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
})

Vue.prototype.$https = https
Vue.config.productionTip = false

//路由拦截
router.beforeEach(function (to, from, next) {
    document.documentElement.scrollTop = 0
    if (store.state.isLogin) {
        https.get('/api/message/list').then(res => {
            if (res.errcode) return;
            store.commit('newsNumber', res.data.unread || 0)
        })
    }
    next()
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
